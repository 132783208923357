import { groupBy, orderBy, QueryNode, select } from '@parse-it/database'
import { ICareersPageReportingFilter } from '../reports.interface'
import { attachCareersPageDateFilterToQuery } from '../reports.util'
import { ReportDatabaseTables } from './reports.schema'

export interface SiteVisits {
  event_date: string
  total_visits: number
}

export const generateSiteVisitsQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const queryNode: QueryNode = {
    type: 'query',
    selects: select('event_date', { name: 'COUNT(user_pseudo_id)', alias: 'total_visits' }),
    from: ReportDatabaseTables.CAREERS_PAGE_ANALYTICS,
    groupBy: groupBy('event_date'),
    orderBy: [orderBy('event_date', 'ASC')],
  }

  attachCareersPageDateFilterToQuery(queryNode, dateFilter)

  return queryNode
}

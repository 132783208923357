import { conditions, groupBy, join, orderBy, QueryNode, select, where } from '@parse-it/database'
import { ReportDatabaseTables } from './reports.schema'

export const generateSwimlaneReportQuery = (jobId: string | undefined): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'JSON_VALUE(a.jobPostId)', '=', 'j.id', 'LEFT')],

    selects: select('a.customerKey', 'JSON_VALUE(a.applicationState) as applicationState', {
      name: 'COUNT(1)',
      alias: 'totalApplications',
    }),
    from: ReportDatabaseTables.JOB_POST_JOB_APPLICATION + ' as a',
    groupBy: groupBy(['a.customerKey', 'applicationState']),
    orderBy: [orderBy('applicationState', 'DESC')],
  }

  if (jobId) {
    query.where = where(conditions([{ column: 'j.id', operator: '=', value: jobId }]))
  }

  return query
}

import { conditions, join, QueryNode, select, where } from '@parse-it/database'
import { ReportDatabaseTables } from './reports.schema'

export const generateEngagementReportQuery = (jobId: string | undefined): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'a.jobPostId', '=', 'j.id', 'LEFT')],
    selects: select(
      'a.jobPostId',
      'a.firstAppliedDate',
      'a.lastAppliedDate',
      'a.totalApplicants',
      'a.inactiveApplicants',
      'a.interviewSessionsCompleted',
      'a.interviewSessionsPending',
      'a.fivsSubmitted',
      'a.fivRequestsPending',
      'a.fivRequestsCompleted',
      'a.updateRequestsCompleted',
      'a.updateRequestsPending'
    ),
    from: ReportDatabaseTables.JOB_CANDIDATE_APPLICATION_STATS + ' as a',
  }

  if (jobId) {
    query.where = where(conditions([{ column: 'j.id', operator: '=', value: jobId }]))
  }

  return query
}

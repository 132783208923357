import { conditions, groupBy, QueryNode, select, where } from '@parse-it/database'
import { ICareersPageReportingFilter } from '../reports.interface'
import { attachCareersPageDateFilterToQuery } from '../reports.util'
import { ReportDatabaseTables } from './reports.schema'

export const generateInProgressApplicationsQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    selects: select('customerKey', { name: 'count(1)', alias: 'inProgressCount' }),
    from: ReportDatabaseTables.CANDIDATE_APPLICATIONS,
    where: where(conditions([{ column: 'JSON_VALUE(applicationState)', operator: '=', value: 'IN_PROGRESS' }])),
    groupBy: groupBy(['customerKey']),
  }

  if (dateFilter) {
    attachCareersPageDateFilterToQuery(query, dateFilter, 'STRING(timestamp(timestamp_seconds(INT64(__createdAt._seconds))))')
  }

  return query
}

import { JobPostStatus } from '@employer/app/models/job-post.model'
import { dateBetween, FilterTransformationFn } from '@engineering11/reporting-web'
import { isJsDate } from '@engineering11/utility'
import { conditions, ExpressionNode, FilterNode, QueryNode, updateOrAddCondition } from '@parse-it/database'
import * as Queries from './queries'
import { ICareersPageReportingFilter } from './reports.interface'

export { Queries }

export const transformGlobalDateFilters = (dateColumn: string): FilterTransformationFn => {
  return (globalFilters: ExpressionNode[], groupFilters: ExpressionNode[], queryNode: QueryNode) => {
    const defaultFilter: FilterNode = queryNode?.where ?? { type: 'filter', operator: 'AND', conditions: [] }
    const modified = globalFilters.map(filter => {
      if (filter.operator !== 'BETWEEN') {
        return filter
      }

      const right = filter.right as ExpressionNode
      if (typeof right.left !== 'string' || !right.left.includes(' AND ')) {
        return filter
      }

      const [start, end] = right.left.split(' AND ').map(d => d.replace(/'/g, '').replace(/"/g, '').trim())
      if (isJsDate(new Date(start)) && isJsDate(new Date(end))) {
        return dateBetween(dateColumn, [start, end])
      }

      return filter
    })

    return modified.reduce((prev, curr) => updateOrAddCondition(prev, curr), defaultFilter)
  }
}

export const JobPostStatusIndicator: Record<JobPostStatus, string> = {
  [JobPostStatus.Published]: 'e11-text-skin-success',
  [JobPostStatus.Deleted]: 'e11-text-skin-alert',
  [JobPostStatus.ClosedFilled]: 'e11-text-skin-primary-accent',
  [JobPostStatus.ClosedNotFilled]: 'e11-text-skin-secondary',
  [JobPostStatus.Draft]: 'e11-text-skin-warning',
}

export const convertGoogleAnalyticsDateToDateObject = (numericDate: number | string): Date => {
  const dateStr = numericDate.toString()

  // Extract year, month, and day
  const year = parseInt(dateStr.substring(0, 4))
  const month = parseInt(dateStr.substring(4, 6)) - 1
  const day = parseInt(dateStr.substring(6, 8))

  return new Date(year, month, day)
}

/**
 * Attach date filter to query node if date filter is provided
 *
 * NB: The provided query node is mutated
 * @param queryNode - Query node to attach date filter to
 * @param dateFilter - Date filter to attach
 * @param customColumn - Custom column to use for date filter
 * @returns Query node with date filter attached
 */
export const attachCareersPageDateFilterToQuery = (
  queryNode: QueryNode,
  dateFilter: ICareersPageReportingFilter | undefined,
  customColumn?: string
) => {
  if (!dateFilter) {
    return queryNode
  }

  const { startDate, endDate } = dateFilter
  const timestampColumn = customColumn ?? `STRING(TIMESTAMP(PARSE_DATE("%Y%m%d", CAST(event_date as string))))`
  const existingWhere = queryNode.where ?? { type: 'filter', operator: 'AND', conditions: [] }
  if (startDate && endDate) {
    existingWhere.conditions.push(dateBetween(timestampColumn, [startDate, endDate]))
  } else if (startDate) {
    existingWhere.conditions.push(conditions([{ column: timestampColumn, operator: '>=', value: startDate }]))
  } else if (endDate) {
    existingWhere.conditions.push(conditions([{ column: timestampColumn, operator: '<=', value: endDate }]))
  }

  queryNode.where = existingWhere
  return queryNode
}

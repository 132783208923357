import { conditions, groupBy, join, orderBy, QueryNode, select, where } from '@parse-it/database'
import { ReportDatabaseTables } from './reports.schema'

export const generateApplicationReportChartQuery = (jobId: string | undefined): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'JSON_VALUE(a.jobPostId)', '=', 'j.id', 'LEFT')],

    selects: select('a.customerKey', 'STRING(date(timestamp_seconds(INT64(__createdAt._seconds)))) as __createdAt', {
      name: 'COUNT(1)',
      alias: 'totalApplications',
    }),
    from: ReportDatabaseTables.JOB_POST_JOB_APPLICATION + ' as a',
    groupBy: groupBy(['a.customerKey', '__createdAt']),
    orderBy: [orderBy('__createdAt', 'ASC')],
  }

  if (jobId) {
    query.where = where(conditions([{ column: 'j.id', operator: '=', value: jobId }]))
  }

  return query
}

export const generateTotalApplicationsQuery = (): QueryNode => {
  return {
    type: 'query',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'JSON_VALUE(a.jobPostId)', '=', 'j.id', 'LEFT')],
    selects: select({ name: 'COUNT(1)', alias: 'totalApplicants' }),
    from: ReportDatabaseTables.JOB_POST_JOB_APPLICATION + ' as a',
  }
}

import { QueryNode, conditions, groupBy, join, select, where } from '@parse-it/database'
import { ICareersPageReportingFilter } from '../reports.interface'
import { attachCareersPageDateFilterToQuery } from '../reports.util'
import { ReportDatabaseTables } from './reports.schema'

/**
 * Extracts job post ID from page location URL
 * Example: '/job/abc123' -> 'abc123'
 */
const JOB_POST_ID_EXTRACT = "REGEXP_EXTRACT(a.page_location, '/job/([^/?]+)')"

/**
 * Generates a query to get job post views and application starts
 * This query joins careers page analytics with job post details to get view counts
 */
const generateVisitorsQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    selects: select(
      { name: JOB_POST_ID_EXTRACT, alias: 'jobPostId' },
      { name: "COUNT(CASE WHEN a.event_name = 'page_view' THEN a.user_pseudo_id END)", alias: 'views' },
      { name: "COUNT(CASE WHEN a.event_name = 'apply_job_link_clicked' THEN a.user_pseudo_id END)", alias: 'applicationStarted' },
      'a.customerKey',
      'j.jobTitle'
    ),
    from: ReportDatabaseTables.CAREERS_PAGE_ANALYTICS + ' AS a',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' AS j', JOB_POST_ID_EXTRACT, '=', 'j.id')],
    where: where(
      conditions([
        { column: 'a.customerKey', operator: 'IS NOT', value: null },
        { column: JOB_POST_ID_EXTRACT, operator: 'IS NOT', value: null },
      ])
    ),
    groupBy: groupBy(['a.customerKey', 'jobPostId', 'j.jobTitle']),
  }

  if (dateFilter) {
    attachCareersPageDateFilterToQuery(query, dateFilter, 'STRING(TIMESTAMP(PARSE_DATE("%Y%m%d", CAST(a.event_date as string))))')
  }

  return query
}

/**
 * Generates a query to get submitted applications count
 * This query counts applications that were submitted through the careers page
 */
const generateSubmissionsQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    selects: select({ name: 'JSON_VALUE(j.jobPostId)', alias: 'jobPostId' }, { name: 'COUNT(1)', alias: 'applicationSubmitted' }),
    from: ReportDatabaseTables.JOB_POST_JOB_APPLICATION + ' AS j',
    groupBy: groupBy(['jobPostId']),
  }

  if (dateFilter) {
    attachCareersPageDateFilterToQuery(query, dateFilter, 'STRING(timestamp(timestamp_seconds(INT64(j.__createdAt._seconds))))')
  }

  return query
}

/**
 * Generates the main careers page analytics query
 * This query combines:
 * 1. Job post views and application starts from careers page
 * 2. Submitted applications count
 *
 * The result provides a complete view of job post performance on the careers page
 */
export const generateCareersPageAnalyticsQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const visitorsQuery = generateVisitorsQuery(dateFilter)
  const submissionsQuery = generateSubmissionsQuery(dateFilter)

  return {
    type: 'query',
    with: [
      {
        name: 'careers_page_job_post_visitors',
        type: 'with',
        query: visitorsQuery,
      },
      {
        name: 'careers_page_job_post_submissions',
        type: 'with',
        query: submissionsQuery,
      },
    ],
    selects: select('a.jobPostId', 'a.views', 'a.applicationStarted', 'a.customerKey', 'a.jobTitle', 'app.applicationSubmitted'),
    from: 'careers_page_job_post_visitors AS a',
    joins: [join('careers_page_job_post_submissions AS app', 'a.jobPostId', '=', 'app.jobPostId')],
  }
}

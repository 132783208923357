import { conditions, groupBy, join, QueryNode, select, where } from '@parse-it/database'
import { ReportDatabaseTables } from './reports.schema'
export const generateComplianceApplicationRejectionsQuery = (): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'JSON_VALUE(a.jobId)', '=', 'j.id', 'LEFT')],
    selects: select(
      {
        name: `dispositionCode`,
        alias: 'reason',
      },
      {
        name: 'COUNT(1)',
        alias: 'count',
      }
    ),
    from: ReportDatabaseTables.JOB_APPLICATION_COMPLIANCE_DEPOSITION + ' as a',
    where: where(conditions([{ column: 'dispositionCode', operator: 'IS NOT', value: null }])),
    groupBy: groupBy(['reason']),
  }

  return query
}

import { QueryNode, select } from '@parse-it/database'
import { ICareersPageReportingFilter } from '../reports.interface'
import { attachCareersPageDateFilterToQuery } from '../reports.util'
import { ReportDatabaseTables } from './reports.schema'

export const generateEngagementTimeQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const queryNode: QueryNode = {
    type: 'query',
    selects: select({ name: 'AVG(engagement_time_msec)', alias: 'avg_time_on_site' }),
    from: ReportDatabaseTables.CAREERS_PAGE_ANALYTICS,
  }

  attachCareersPageDateFilterToQuery(queryNode, dateFilter)
  return queryNode
}

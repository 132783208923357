import { QueryNode, conditions, where } from '@parse-it/database'
import { ReportDatabaseTables } from './reports.schema'

export const generateJobPostReportQuery = (
  sortBy?: { column: string; direction: 'DESC' | 'ASC' },
  limit?: number,
  offset?: number,
  jobPostId?: string
): QueryNode => {
  const query: QueryNode = {
    type: 'query',
    selects: [
      'jobTitle',
      'jobPaused',
      'totalApplicants',
      'status',
      'STRING(timestamp(timestamp_seconds(INT64(__updatedAt._seconds)))) as __updatedAt',
      'STRING(timestamp(timestamp_seconds(INT64(jobPostCreatedAt._seconds)))) as jobPostCreatedAt',
      'STRING(timestamp(timestamp_seconds(INT64(publishedDate._seconds)))) as publishedDate',
      'id',
      'customerKey',
      'locationAddress',
      'department',
      'employmentType',
      'experienceLevel',
    ],
    from: ReportDatabaseTables.JOB_POST_DETAIL,
    orderBy: sortBy ? [{ column: sortBy.column, direction: sortBy.direction, type: 'orderby' }] : [],
  }

  if (limit) {
    query.limit = limit
  }

  if (offset) {
    query.offset = offset
  }

  if (jobPostId) {
    query.where = where(conditions([{ column: 'id', operator: '=', value: jobPostId }]))
  }

  return query
}

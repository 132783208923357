import { conditions, QueryNode, select, where } from '@parse-it/database'
import { ICareersPageReportingFilter } from '../reports.interface'
import { attachCareersPageDateFilterToQuery } from '../reports.util'
import { ReportDatabaseTables } from './reports.schema'

export const generateClickQuery = (dateFilter?: ICareersPageReportingFilter): QueryNode => {
  const subquery: QueryNode = {
    type: 'query',
    selects: select('customerKey', 'user_pseudo_id', {
      name: 'COUNT(*)',
      alias: 'click_count',
    }),
    from: ReportDatabaseTables.CAREERS_PAGE_ANALYTICS,
    where: where(conditions([{ column: 'event_name', operator: '=', value: 'click' }])),
    groupBy: ['customerKey', 'user_pseudo_id'],
  }

  attachCareersPageDateFilterToQuery(subquery, dateFilter)

  const queryNode: QueryNode = {
    type: 'query',
    selects: select({
      name: 'COALESCE(AVG(click_count), 0)',
      alias: 'avg_clicks_per_visitor',
    }),
    from: {
      type: 'subquery',
      alias: 'user_clicks',
      query: subquery,
    },
  }

  return queryNode
}

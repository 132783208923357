export const ReportDatabaseTables = {
  JOB_POST_DETAIL: 'job_post_custom_job_post_detail',
  JOB_POST_JOB_APPLICATION: 'jobs_job_post__jobs_job_application_expanded_latest',
  JOB_APPLICATION_COMPLIANCE_DEPOSITION: 'jobs_job_application_compliance_deposition_code_stats_view',
  JOB_CANDIDATE_APPLICATION_STATS: 'jobs_candidate_application_stats_view',
  CAREERS_PAGE_ANALYTICS: 'careers_page_analytics_expanded',
  CANDIDATE_APPLICATIONS: 'jobs_candidate_application_expanded_latest',
  JOB_POST_FIRST_PUBLISHED: 'job_post_first_published_date_view',
} as const

export type ReportDatabaseTable = (typeof ReportDatabaseTables)[keyof typeof ReportDatabaseTables]

import { QueryNode, conditions, groupBy, join, select, where } from '@parse-it/database'
import { ICandidateComplianceResponses } from 'shared-lib'
import { ReportDatabaseTables } from './reports.schema'

export const generateComplianceReportQueryFor = (key: keyof ICandidateComplianceResponses): QueryNode => ({
  type: 'query',
  joins: [join(ReportDatabaseTables.JOB_POST_DETAIL + ' as j', 'JSON_VALUE(a.jobPostId)', '=', 'j.id', 'LEFT')],
  selects: select(
    {
      name: `JSON_EXTRACT_SCALAR(candidateComplianceResponses, "$.${key}")`,
      alias: 'key',
    },
    {
      name: 'COUNT(1)',
      alias: 'count',
    }
  ),
  from: ReportDatabaseTables.JOB_POST_JOB_APPLICATION + ' as a',
  where: where(conditions([{ column: 'candidateComplianceResponses', operator: 'IS NOT', value: null }])),
  groupBy: groupBy(['key']),
})
